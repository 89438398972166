import styles from './styles.module.scss';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import clsx from 'clsx';
import { CSSProperties, forwardRef } from 'react';

type Props = {
  id?: string;
  name: string;
  options?: readonly {
    label: string;
    value: string | number;
  }[];
  isDisabled?: boolean;
  autoFocus?: boolean;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  defaultValue?: string | number;
  placeHolder?: string;
  placeHolderDisabled?: boolean;
  hasError?: boolean;
  tabIndex?: number;
  value?: string;
  width?: CSSProperties['width'];
};

export const SelectBox = forwardRef<HTMLSelectElement, Props>((props, ref) => (
  <div className={styles.wrapper} style={{ width: props.width }}>
    <select
      id={props.id}
      name={props.name}
      className={clsx([styles.base, props.hasError && styles.error])}
      disabled={props.isDisabled}
      autoFocus={props.autoFocus}
      ref={ref}
      onChange={props.onChange}
      defaultValue={props.defaultValue}
      tabIndex={props.tabIndex}
      value={props.value}
    >
      <option value="" disabled={props.placeHolderDisabled ?? true}>
        {props.placeHolder ?? '選択してください'}
      </option>
      {props.options?.map((item) => (
        <option key={item.value} value={item.value}>
          {item.label}
        </option>
      ))}
    </select>
    <ArrowDropDown
      className={clsx(styles.icon, {
        [styles.disabled]: props.isDisabled,
      })}
    />
  </div>
));
